import React, { useEffect } from 'react'

import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { api } from '@extensiv-app/utility'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Theme } from '@mui/system'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Provider } from 'react-redux'

import App from '@core/App'
import { theme } from '@core/assets/styles/theme'
import { setCognitoSession } from '@core/redux/authSlice'
import store from '@core/redux/store'
import '@core/root.css'
import { ExtensivSnackbar, SnackbarProvider } from '@extensiv/shared-reactcomponents'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const muiCache = createCache({
  'key': 'mui',
  'prepend': true,
})

api.onUserSessionUpdate = (updatedSession: CognitoUserSession) => {
  store.dispatch(setCognitoSession(updatedSession))
}

// ClassNameGenerator.configure((componentName) => `corefe-global-${componentName}`)

const branch = process.env.CI_COMMIT_BRANCH || '<local deploy>'
const commit = process.env.CI_COMMIT_SHORT_SHA || '<local deploy>'

export default () => {
  useEffect(() => {
    console.log(`%cCORE FE GLOBAL branch=${branch} commit=${commit}`, ' color: greenfont-size:18px')
  }, [])

  return (
    <React.StrictMode>
      <Provider store={ store }>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <CacheProvider value={ muiCache }>
          <ThemeProvider theme={ theme }>
            <SnackbarProvider maxSnack={ 3 } Components={{
              extensivSnackbar: ExtensivSnackbar,
            }} classes={{ containerRoot: 'snackbar' }}>
              <CssBaseline />
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </CacheProvider>
        {/* </PersistGate> */}
      </Provider>
    </React.StrictMode>
  )
}
