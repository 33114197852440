import { Route, Redirect } from 'react-router-dom'

import { AUTH_ROUTES } from '@core/constants/coreRoutes'
import AppLayout from '@core/containers/AppLayout'
import { useAppSelector } from '@core/redux/store'
import { isAuthRoutes } from '@core/services/auth.service'

const PrivateRoute: any = ({ component, ...rest }: { component: any, restricted: boolean }) => {
  const Component = component
  const { cognitoSession } = useAppSelector((state: any) => state.auth)
  const isAuthenticated = cognitoSession && cognitoSession.idToken ? true : false

  return (
    <Route
      { ...rest }
      render={ (props: any) => {
        if (!isAuthRoutes(props.match.url) && isAuthenticated) {
          return (<AppLayout><Component { ...props } /></AppLayout>)
        }
        return (<Redirect to={ AUTH_ROUTES.LOGIN } />)
      } }
    />
  )
}

export default PrivateRoute
