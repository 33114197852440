import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { setCognitoSession } from '@core/redux/authSlice'
import { setIsOpenMfaDialog, setIsShowLoginFailedDialog } from '@core/redux/loginSlice'
import { showNotification } from '@core/redux/snackbarSlice'
import {
  sendCustomChallengeAnswer as sendCustomChallengeAnswerApi,
  setDeviceStatusRemembered as setDeviceStatusRememberedApi,
} from '@core/services/auth.service'
import { onChangeAuth } from '@core/util/commonFunctions'

export const sendCustomChallengeAnswer = createAsyncThunk(
  'auth/sendCustomChallengeAnswer',
  async (code: string, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await sendCustomChallengeAnswerApi(code)
      const { type, data } = response

      if (type === 'onSuccess') {
        dispatch(setIsOpenMfaDialog(false))
        dispatch(setCognitoSession(data))
        onChangeAuth(dispatch, data)
      } else {
        dispatch(showNotification({
          type: 'error',
          msg: 'The verification code you entered is invalid or expired. To request a new code, please click Cancel, then re-enter your email and password.'
        }))
      }
      return response
    }
    catch (err: any) {
      // console.log('err', err)
      if (err?.data?.code === 'InvalidLambdaResponseException') {
        dispatch(setIsShowLoginFailedDialog(true))
      } else if (err?.data?.code === 'NotAuthorizedException'){
        dispatch(showNotification({
          type: 'error',
          msg: 'Your login session has expired. To request a new verification code, please re-enter your email and password.'
        }))
      } else {
        dispatch(showNotification({
          type: 'error',
          msg: 'Something went wrong, please try again'
        }))
      }
      dispatch(setIsOpenMfaDialog(false))
      return rejectWithValue(err)
    }
  }
)

export const setDeviceStatusRemembered = createAsyncThunk(
  'auth/setDeviceStatusRemembered',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await setDeviceStatusRememberedApi()
      return response
    }
    catch (err: any) {
      console.log('err', err)
      dispatch(showNotification({ type: 'error', msg: err?.data?.message }))
      return rejectWithValue(err)
    }
  }
)


export const mfaSlice = createSlice({
  name: 'mfaDialog',
  initialState: {
    isLoading: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sendCustomChallengeAnswer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendCustomChallengeAnswer.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(sendCustomChallengeAnswer.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(setDeviceStatusRemembered.pending, (state) => {
        state.isLoading = true
      })
      .addCase(setDeviceStatusRemembered.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(setDeviceStatusRemembered.rejected, (state) => {
        state.isLoading = false
      })
  },
})

// export const { } = mfaSlice.actions

export default mfaSlice.reducer
