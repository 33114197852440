import * as globalUtility from '@extensiv-app/utility'
import { HubProduct } from '@extensiv-app/utility'
import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import moment from 'moment'

export interface AppState {
  selectedApp: globalUtility.IHubApplication | null
  activeProducts: any
  isFormEdited: boolean
  isShowBackdrop: boolean
  newCurrentAppVersionDetails: any
  recentlyUpdatedApps: any[]
  appsNeedingReloadForUpdate: string[]
}

const defaultState: AppState = {
  selectedApp: null,
  activeProducts: {},
  isFormEdited: false,
  isShowBackdrop: false,
  newCurrentAppVersionDetails: null,
  recentlyUpdatedApps: [] as any[],
  appsNeedingReloadForUpdate: []
}

export const appSlice: any = createSlice({
  name: 'application',
  initialState: cloneDeep(defaultState),
  reducers: {
    clearStateApplication: (state) => {
      state = cloneDeep(defaultState)
      return state
    },
    setIsFormEdited: (state, { payload }) => {
      state.isFormEdited = payload
      return state
    },
    setIsShowBackdrop: (state, { payload }) => {
      state.isShowBackdrop = payload
      return state
    },
    setSelectedApp: (state, { payload }: { payload: string }) => {
      const ApplicationList = globalUtility.applicationList
      let appName = payload

      if (!Object.hasOwn(ApplicationList, appName)) {
        appName = HubProduct.HUB
      }

      state.selectedApp = ApplicationList[appName as keyof typeof ApplicationList]
    },
    setActiveProducts: (state, action: any) => {
      state.activeProducts = action.payload
    },
    setCurrentAppOutdated: (state, { payload }: any) => {
      console.log('here', payload)
      state.newCurrentAppVersionDetails = payload
    },
    setAppVersionUpdateAvailable: (state, { payload }: any) => {
      state.recentlyUpdatedApps = [
        ...(state.recentlyUpdatedApps.filter(({ app }) => app != payload.app)),
        payload
      ]
      state.appsNeedingReloadForUpdate = [
        ...state.appsNeedingReloadForUpdate,
        payload.app
      ]

      let storedAppUpdateDetails: { [key: string]: any } = {}
      const storedAppUpdateDetailsJSON = localStorage.getItem('appUpdates')
      if (storedAppUpdateDetailsJSON) {
        storedAppUpdateDetails = JSON.parse(storedAppUpdateDetailsJSON)
      }

      storedAppUpdateDetails[payload.app] = {
        ...payload,
        updateTime: moment()
      }

      localStorage.setItem('appUpdates', JSON.stringify(storedAppUpdateDetails))
    }
  },
  extraReducers: {},
})

export const {
  clearStateApplication,
  setSelectedApp,
  setIsFormEdited,
  setIsShowBackdrop,
  setActiveProducts,
  setCurrentAppOutdated,
  setAppVersionUpdateAvailable
} = appSlice.actions

export default appSlice.reducer
