export const AUTH_ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  CREATE_PASSWORD: '/create-password',
  REGISTER: '/register',
  OOPS: '/direct-login-links',
  SSO: '/sso'
}

export const CORE_APP_ROOT = '/hub'

export const USER_PROFILE = `${CORE_APP_ROOT}/user-profile`

export const LINK_OM = '/link-om'

export const LOGIN_PREVIEW = '/login-preview'
