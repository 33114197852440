import { IExtendedUser, IOrganization } from '@extensiv/sdk/hub'
import { NetworkResponse } from '@extensiv/shared-reactcomponents'
import { api, onErrorResponse } from '@extensiv-app/utility'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { find } from 'lodash'

import {
  setIsCreatePasswordRequired,
  setIsShowChangePasswordDialog,
  setNewPasswordSessionUserAttributes,
  setIsOpenVerifyEmailDialog,
  setIsShowLoginFailedDialog,
  setIsOpenMfaDialog
} from '@core/redux/loginSlice'
import { showNotification } from '@core/redux/snackbarSlice'
import { AppDispatch, RootState } from '@core/redux/store'
import {
  signIn as authSignIn,
  globalSignOut as authGlobalSignOut,
  getSession as authGetSession,
  completeNewPasswordChallenge as authCompleteNewPasswordChallenge,
  authGetUserInfo,
  setSelectedOrgToUserAttribute
} from '@core/services/auth.service'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LoginResponse, LoginError } from '@core/types/Authentication'
import { setProductAccessFromUserOrOrg, initializePendo } from '@core/util/commonFunctions'

export interface AuthState {
  currentUser: IExtendedUser | null
  currentOrg: IOrganization | null
  currentOrgId: string | null
  cognitoSession: CognitoUserSession | null
  isLoading: boolean
}

const initialState: AuthState = {
  currentUser: null,
  currentOrg: null,
  currentOrgId: null,
  cognitoSession: null,
  isLoading: false
}

export const login = createAsyncThunk<
  LoginResponse,
  { email: string, password: string }
>(
  'auth/login',
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await authSignIn({ Username: email, Password: password })

      if (response.type === 'onSuccess') {
        await dispatch(setCognitoSession(response.data))
        await dispatch(getUserInfoUsingAuth(response.data))
      } else if (response.type === 'totpRequired' || response.type === 'customChallenge') {
        setIsOpenMfaDialog(true)
      } else if (response.type === 'newPasswordRequired') {
        dispatch(showNotification({
          type: 'info',
          msg: 'Please create new password to continue.'
        }))
        dispatch(setIsCreatePasswordRequired(true))
        dispatch(setNewPasswordSessionUserAttributes(response.data))
      }

      return response
    } catch (err: LoginError | any) {
      if (
        err?.data?.code !== 'InvalidLambdaResponseException'
        && ![ 'UserNotConfirmedException', 'PasswordResetRequiredException', 'NotAuthorizedException' ].includes(err?.code)
      ) {
        dispatch(showNotification({
          type: 'error',
          msg: err?.message || err?.data?.message
        }))
      }

      if (err?.code === 'UserNotConfirmedException') {
        dispatch(setIsOpenVerifyEmailDialog(true))
      } else if (err?.data?.code === 'InvalidLambdaResponseException') {
        dispatch(setIsShowLoginFailedDialog(true))
      } else if (err?.code === 'PasswordResetRequiredException') {
        dispatch(setIsShowChangePasswordDialog(true))
      }

      return rejectWithValue(err)
    }
  }
)

export const getUserInfoUsingAuth = createAsyncThunk<
  void,
  CognitoUserSession | null,
  { dispatch: AppDispatch, state: RootState }
>(
  'auth/getUserInfoUsingAuth',
  async (authSession, { dispatch, getState }) => {
    if (!authSession?.getIdToken()?.payload) {
      dispatch(setCurrentOrganization({ orgKey: null }))
      return
    }

    const orgKey: string = authSession.getIdToken().payload['custom:org']
    const extensivId: string = authSession.getIdToken().payload['extensivId']
    const userId: string = authSession.getIdToken().payload['cognito:username']

    const { type, payload } = await dispatch(getUserInfo(userId))

    if (type?.includes('fulfilled') && payload.data) {
      await setProductAccessFromUserOrOrg(dispatch, payload.data, orgKey)
      initializePendo(payload.data, orgKey, extensivId)
    }

    const currentOrg = getState().auth.currentOrg
    if (currentOrg != orgKey) {
      dispatch(setCurrentOrganization({ orgKey, updateCognito: currentOrg != null }))
    }
  }
)

export const getUserInfo = createAsyncThunk<
  NetworkResponse<IExtendedUser>,
  string
>(
  'auth/userInfo',
  async (id: string, { rejectWithValue }) => {
    try {
      return await authGetUserInfo(id)
    } catch (err: any) {
      console.log('err', err)
      return rejectWithValue(err)
    }
  }
)



export const logout = createAsyncThunk(
  'auth/logout',
  async (state, { dispatch, rejectWithValue }) => {
    try {
      return await authGlobalSignOut()
    } catch (err: any) {
      console.log('err', err)
      return rejectWithValue(err)
    }
  }
)

export const completeNewPasswordChallenge = createAsyncThunk(
  'auth/completeNewPasswordChallenge',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await authCompleteNewPasswordChallenge(payload)
      await dispatch(getUserInfoUsingAuth(response?.data))

      dispatch(showNotification({
        type: 'info',
        msg: response?.message || 'Your Password has been successfully changed.'
      }))

      dispatch(setIsCreatePasswordRequired(false))

      return response
    } catch (err: any) {
      console.log('err', err)
      if (err?.code !== 'UserNotConfirmedException' && err?.data?.code !== 'InvalidLambdaResponseException') {
        dispatch(showNotification({
          type: 'error',
          msg: err?.message || err?.data?.message
        }))
      }
      return rejectWithValue(err)
    }
  }
)

export const getSession = createAsyncThunk(
  'auth/getSession',
  async (state, { dispatch, rejectWithValue }) => {
    try {
      const response: CognitoUserSession = await authGetSession()
      await dispatch(setCognitoSession(response))
      await dispatch(getUserInfoUsingAuth(response))
      //To not remember device setDeviceStatusNotRemembered()
      // console.log('response', response)
      return response
    }
    catch (err: any) {
      console.log('err', err)
      return rejectWithValue(err)
    }
  }
)

export const setCurrentOrganization = createAsyncThunk<
  CognitoUserSession,
  { orgKey: string | null, updateCognito?: boolean },
  { rejectValue: any }
>(
  'organization/setSelected',
  async ({ orgKey, updateCognito = true }, { dispatch, rejectWithValue }) => {

    if (!updateCognito) return await authGetSession()



    try {
      return await setSelectedOrgToUserAttribute(orgKey)
    } catch (err: any) {
      onErrorResponse(err)
      return rejectWithValue(err)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCognitoSession: (state, { payload }: PayloadAction<CognitoUserSession>) => {
      api.authToken = payload.getIdToken().getJwtToken()
      state.cognitoSession = payload
    },
    setCurrentUser: (state, action: PayloadAction<IExtendedUser>) => {
      state.currentUser = action.payload
    },
    setCurrentOrgLogo: (state, action: PayloadAction<string>) => {
      console.log('setCurrentOrgLogo', state.currentOrg, action.payload)
      if (state.currentOrg) {
        state.currentOrg.logo = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.isLoading = true
      })
      .addCase(logout.pending, state => {
        state.isLoading = true
      })
      .addCase(getSession.pending, state => {
        state.isLoading = true
      })
      .addCase(setCurrentOrganization.pending, state => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, { payload }: PayloadAction<LoginResponse>) => {
        state.isLoading = false

        if (payload.type === 'onSuccess') {
          state.cognitoSession = payload.data
        }
      })
      .addCase(logout.fulfilled, state => {
        state.cognitoSession = null
        state.currentUser = null
        state.isLoading = false
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }: PayloadAction<NetworkResponse<IExtendedUser>>) => {
        state.currentUser = payload.data || null
      })
      .addCase(completeNewPasswordChallenge.fulfilled, (state, { payload }: PayloadAction<LoginResponse>) => {
        state.cognitoSession = payload.data
      })
      .addCase(getSession.fulfilled, (state, { payload }: PayloadAction<CognitoUserSession>) => {
        state.cognitoSession = payload
        state.isLoading = false
      })
      .addCase(setCurrentOrganization.fulfilled, (state, { payload }: PayloadAction<CognitoUserSession>) => {
        state.currentOrg = find(state.currentUser?.orgs || [], { key: payload.getIdToken().payload.org }) || null
        state.isLoading = false
      })
      .addCase(login.rejected, state => {
        state.isLoading = false
      })
      .addCase(logout.rejected, state => {
        state.isLoading = false
      })
      .addCase(getSession.rejected, state => {
        state.isLoading = false
      })
      .addCase(setCurrentOrganization.rejected, state => {
        state.isLoading = false
      })
  },
})

export const { setCognitoSession, setCurrentUser, setCurrentOrgLogo } = authSlice.actions

export const isSuperOrg = (state: any) => [ 'true', true ].includes(state?.auth?.cognitoSession?.idToken?.payload?.issuperorg)
export const isSuperUser = (state: any) => [ 'true', true ].includes(state?.auth?.currentUser?.isSuperUser)
export const getSuperOrg = (state: any) => {
  const organizations = state?.auth?.currentUser?.orgs || []
  const superOrganization = find(organizations, { isSuperOrg: true }) || null

  return superOrganization
}
export const getUsersPermissions = (state: any) => {
  const permissions = state?.auth?.cognitoSession?.idToken?.payload?.perms ? JSON.parse(state.auth.cognitoSession.idToken.payload.perms) : []
  return permissions
}

export default authSlice.reducer
