import { useEffect, useState, useMemo } from 'react'

import { IOrganization } from '@extensiv/sdk/hub'
import { CorePopper, CoreLink, Text } from '@extensiv/shared-reactcomponents'
import * as globalUtility from '@extensiv-app/utility'
import { HubProduct } from '@extensiv-app/utility'
import {
  KeyboardArrowDown, AccountCircle, Apps,
  Settings, Code, Group, ExitToApp, Support,
  Help, StarHalf, School
} from '@mui/icons-material'
import {
  Avatar,
  AppBar,
  Autocomplete,
  TextField,
  Toolbar,
  MenuItem,
  Box,
  IconButton,
  Typography,
  Button,
  Tooltip,
  CircularProgress
} from '@mui/material'
import { filter, includes } from 'lodash'
import { useHistory } from 'react-router-dom'

import ExtensivLogo from '@core/assets/img/extensiv-logo-200.svg'
import CssVariables from '@core/assets/styles/css-variables'
import HeaderAppMenu from '@core/components/HeaderAppMenu'
import { CORE_APP_ROOT, USER_PROFILE } from '@core/constants/coreRoutes'
import { setIsShowBackdrop } from '@core/redux/appSlice'
import { isSuperOrg, isSuperUser, setCurrentOrganization } from '@core/redux/authSlice'
import { logout, setCognitoSession, getUserInfoUsingAuth } from '@core/redux/authSlice'
import { showNotification } from '@core/redux/snackbarSlice'
import { useAppDispatch, useAppSelector } from '@core/redux/store'
import { setLoginFlagOnLocalStorage } from '@core/services/localstorage'
import useUserNotification from '@core/websockets/useUserNotificationHook'


import useStyles from './styles'


interface IProps {
  open?: boolean
}

const collator = new Intl.Collator('en', {
  usage: 'sort',
  ignorePunctuation: true,
  sensitivity: 'base', // "A a B b" instead of "A B a b"
  numeric: true, // "1 2 10 20" instead of "1 10 2 20"
})

export default ({ open = true }: IProps) => {
  const cmpSelector = 'Header'
  const { classes, cx } = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const ApplicationList = globalUtility.applicationList
  const isSuperUserFlag = useAppSelector(isSuperUser)
  const isSuperOrgFlag = useAppSelector(isSuperOrg)
  const { isLoading, cognitoSession, currentUser, currentOrg } = useAppSelector((state: any) => state.auth)
  const { activeProducts, isFormEdited, selectedApp } = useAppSelector((state: any) => state.application)
  const [ availableProducts, setAvailableProducts ] = useState<any>([])
  const organizations = currentUser?.orgs || []
  const [ isAppsMenuOpen, setIsAppsMenuOpen ] = useState(false)
  const [ isCustomerCentralMenuOpen, setIsCustomerCentralMenuOpen ] = useState(false)
  const [ isProfileMenuOpen, setIsProfileMenuOpen ] = useState(false)
  const initialCopyText = 'Copy Extensiv ID'
  const [ copyText, setCopyText ] = useState<string>(initialCopyText)

  useEffect(() => {
    globalUtility.globalSelectedOrg.next(currentOrg)
  }, [ currentOrg ])

  useUserNotification(currentOrg?.key, currentUser?.id)

  useEffect(() => {
    const appAccessForCurrentOrg = cognitoSession?.idToken?.payload?.appaccess || []
    let applications = filter(ApplicationList, (app: any) => {
      return (activeProducts[app.apiFlag] && includes(appAccessForCurrentOrg, app.apiFlag)) || (app?.apiFlag === 'core')
    })

    if (currentOrg) {
      applications = applications.filter(app => {
        return !(app.key == HubProduct.THREEPL_WAREHOUSE_MANAGER && currentOrg.key == 'extensiv')
      })
    }

    setAvailableProducts(applications)
  }, [ activeProducts, cognitoSession, currentOrg ])

  const toggleMenu = (changFunc: any, isOpen: boolean) => () => {
    changFunc(isOpen)
  }

  const handleOrgChange = async (event: any, organization: IOrganization) => {
    event?.preventDefault()
    event?.stopPropagation()

    dispatch(setIsShowBackdrop(true))
    const { type, payload } = await dispatch(setCurrentOrganization({ orgKey: organization.key }))

    if (type?.includes('fulfilled')) {
      await history.push(CORE_APP_ROOT)
      // singleSpa.navigateToUrl(ROOT)
      await dispatch(setCognitoSession(payload))
      await dispatch(getUserInfoUsingAuth(payload))
    }
    dispatch(setIsShowBackdrop(false))
  }

  const handleProfileSettingClick = async () => {
    toggleMenu(setIsProfileMenuOpen, false)()
    history.push(USER_PROFILE)
  }

  const logOut = async () => {
    if (isFormEdited) {
      dispatch(showNotification({ type: 'info', msg: 'You have unsaved changes, please save or disregard changes' }))
      return
    }
    dispatch(logout())
    setLoginFlagOnLocalStorage('false')
  }

  const handleOnLogoImgError = (e: any) => {
    e.target.onerror = null
    e.target.src = ExtensivLogo
  }

  const sortedOrgs = useMemo(() => {
    return organizations
      .filter((o: any) => o.displayName != undefined)
      .sort((o1: any, o2: any) => collator.compare(o1.displayName, o2.displayName))
  }, [ organizations ])

  const copyToClipBoard = async () => {
    try {
      if (!navigator.clipboard) { // If clipboard API is not supported by browser then it will use this deprecated execCommand method
        const textArea = document.createElement('textarea')
        textArea.value = currentOrg?.extensivId || 'No Id'
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)
        setCopyText('Copied')
        return
      }
      await navigator.clipboard.writeText(currentOrg?.extensivId || 'No Id')
      setCopyText('Copied')
    } catch (ex) {
      console.error('Error while coping text to clipboard', ex)
    }
  }

  const ProfileMenuContent = (
    <Box className={ classes.menuList }>
      <div id="dataImportMenuItemPortal"></div>
      <Tooltip
        title={ copyText }
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [ 0, -10 ]
              },
            }
          ],
        }}
      >
        <MenuItem
          className={ classes.extensivIdMenuWrapper }
          data-testid={ `${cmpSelector}ExtensivIdMenu` }
          disableRipple
          onClick={ copyToClipBoard }
          onMouseEnter={ () => { setCopyText(initialCopyText) } }
        >
          <div className={ classes.extensivIdTextWrapper }>
            <div className={ classes.extensivIdTitle }>
              Extensiv ID
            </div>
            <div
              className={ cx(
                classes.extensivId,
                'extensiv-id',
                { 'text-secondary': copyText === 'Copied' }
              ) }
            >
              {currentOrg?.extensivId || 'No ID'}
            </div>
          </div>
        </MenuItem>
      </Tooltip>
      {(!isSuperUserFlag || (isSuperUserFlag && isSuperOrgFlag)) && (
        <MenuItem onClick={ handleProfileSettingClick } data-testid={ `${cmpSelector}ProfileSettingMenu` }>
          <Settings />
          Profile Settings
        </MenuItem>
      )}
      <MenuItem onClick={ logOut } data-testid={ `${cmpSelector}LogoutMenu` } >
        <ExitToApp />
        Sign Out
        <div style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
          {(isLoading) && <div><CircularProgress size="1.5em" /></div>}
        </div>
      </MenuItem>
    </Box>
  )

  const openLink = (link: string) => () => {
    toggleMenu(setIsCustomerCentralMenuOpen, false)()

    window.open(link, '_blank')
  }

  const CustomerCentralMenuContent = (
    <Box className={ classes.wideMenuList }>
      <MenuItem
        onClick={ openLink('https://help.extensiv.com') }
        data-testid={ `${cmpSelector}ApiDocumentationMenu` }
      >
        <Help sx={{ color: '#0E8895 !important' }} />
        <Text extraSmall bold overflowEllipsis={ false }>Help Center</Text>
      </MenuItem>
      <MenuItem
        onClick={ openLink('https://developer.extensiv.com') }
        data-testid={ `${cmpSelector}ApiDocumentationMenu` }
      >
        <Code sx={{ color: '#0E8895 !important' }} />
        <Text extraSmall bold overflowEllipsis={ false }>API Docs</Text>
      </MenuItem>
      <MenuItem
        onClick={ openLink('https://university.extensiv.com') }
        data-testid={ `${cmpSelector}CommunityMenu` }
      >
        <School sx={{ color: '#0E8895 !important' }} />
        <Text extraSmall bold overflowEllipsis={ false }>Training</Text>
      </MenuItem>
      <MenuItem
        onClick={ openLink('https://status.extensiv.com') }
        data-testid={ `${cmpSelector}SystemStatusMenu` }
      >
        <StarHalf sx={{ color: '#0E8895 !important' }} />
        <Text extraSmall bold overflowEllipsis={ false }>System Status</Text>
      </MenuItem>
      <MenuItem
        onClick={ openLink('https://community.extensiv.com') }
        data-testid={ `${cmpSelector}CommunityMenu` }
      >
        <Group sx={{ color: '#0E8895 !important' }} />
        <Text extraSmall bold overflowEllipsis={ false }>Community</Text>
      </MenuItem>
    </Box>
  )

  return (
    <AppBar
      position='fixed'
      className={ cx(classes.appBar, { [classes.appBarShift]: open }) }
      elevation={ 0 }
    >
      <Toolbar
        style={{ borderBottomColor: selectedApp?.themeColor }}
        data-testid={ `${cmpSelector}` }
      >
        <CoreLink
          to={ CORE_APP_ROOT }
          className='HeaderTab-root'
          style={{ borderBottomColor: selectedApp?.themeColor, backgroundColor: 'white' }}
          data-testid={ `${cmpSelector}Logo` }
        >
          <span className={ classes.leftContainerLogo }>
            <img
              src={ currentOrg?.logo || ExtensivLogo }
              onError={ handleOnLogoImgError }
              alt={ selectedApp?.title }
            />
          </span>
        </CoreLink>
        <Autocomplete
          data-testid={ `${cmpSelector}OrganizationAutoComplete` }
          className='HeaderTab-root org-selection'
          options={ sortedOrgs }
          getOptionLabel={ (option) => (isSuperUserFlag ? `${option.extensivId || 'No ID'} - ` : '') + option?.displayName }
          disableClearable
          openOnFocus
          blurOnSelect
          freeSolo={ organizations.length <= 1 } // only if user has more than one organization
          disabled={ organizations.length <= 1 || isFormEdited } // only if user has more than one organization
          popupIcon={ <KeyboardArrowDown /> }
          classes={{ paper: classes.organizationPaper }}
          onChange={ handleOrgChange }
          isOptionEqualToValue={ (option, value) => option?.key == value?.key }
          value={ currentOrg }
          style={ isFormEdited ? { opacity: 0.5 } : {} }
          renderOption={ (props, option, state) => <li { ...props } key={ option.key }>{isSuperUserFlag ? `${option.extensivId || 'No ID'} - ` : ''}{option.displayName}</li> }
          renderInput={ (params) =>
            <TextField
              { ...params }
              variant='standard'
              placeholder='Choose Org'
              inputProps={{ ...params.inputProps, autoComplete: 'off' }}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          }
        />
        <Box className={ `HeaderTab-root ${classes.superNav}` }>
          {availableProducts.length > 1 && (
            <CorePopper
              content={
                <HeaderAppMenu
                  availableProducts={ availableProducts }
                  onAppSelect={ toggleMenu(setIsAppsMenuOpen, false) }
                />
              }
              open={ isAppsMenuOpen }
              placement='bottom'
              onClose={ toggleMenu(setIsAppsMenuOpen, false) }
              arrowColor={ CssVariables.secondary }
              backgroundColor=''
            >
              <Box className='super-nav-menu'>
                <IconButton
                  onClick={ toggleMenu(setIsAppsMenuOpen, !isAppsMenuOpen) }
                  data-testid={ `${cmpSelector}AppsMenuBtn` }
                >
                  <Apps />
                </IconButton>
              </Box>
            </CorePopper>
          )}
          <CorePopper
            content={ CustomerCentralMenuContent }
            title='Resources'
            open={ isCustomerCentralMenuOpen }
            onClose={ toggleMenu(setIsCustomerCentralMenuOpen, false) }
            arrowColor={ CssVariables.paleBlueGrey }
            backgroundColor={ CssVariables.offWhite }
          >
            <Box className={ 'super-nav-menu' }>
              <IconButton onClick={ toggleMenu(setIsCustomerCentralMenuOpen, !isCustomerCentralMenuOpen) } data-testid={ `${cmpSelector}CustomerCentralMenuBtn` }>
                <Support />
              </IconButton>
            </Box>
          </CorePopper>

          <CorePopper
            content={ ProfileMenuContent }
            open={ isProfileMenuOpen }
            placement='bottom'
            onClose={ toggleMenu(setIsProfileMenuOpen, false) }
            arrowColor={ CssVariables.secondary }
            backgroundColor=''
          >
            <div>
              <Button
                variant='contained'
                className='super-nav-menu profile-block'
                onClick={ toggleMenu(setIsProfileMenuOpen, !isProfileMenuOpen) }
                data-testid={ `${cmpSelector}ProfileMenuBtn` }
                id='HeaderProfileMenuBtn'
              >
                {currentUser?.image
                  ? <Avatar className='profile-avatar-tag' alt={ currentUser?.firstName } src={ currentUser?.image } />
                  : <AccountCircle />
                }
                <Box className='profile-details'>
                  <Typography className='user-name'>
                    {currentUser?.firstName && currentUser?.lastName ? `${currentUser?.firstName} ${currentUser?.lastName}` : ''}&nbsp;
                  </Typography>
                  <Typography className='user-role'>
                    {currentUser?.jobTitle ? currentUser?.jobTitle : ''}&nbsp;
                  </Typography>
                </Box>
              </Button>
            </div>
          </CorePopper>
        </Box>
      </Toolbar>
    </AppBar >
  )
}
