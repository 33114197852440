import { Route, Redirect } from 'react-router-dom'

import { CORE_APP_ROOT } from '@core/constants/coreRoutes'
import { useAppSelector } from '@core/redux/store'
import { isAuthRoutes } from '@core/services/auth.service'

const PublicRoute: any = ({ component, ...rest }: { component: any }) => {
  const Component = component
  const { cognitoSession } = useAppSelector((state: any) => state.auth)
  const isAuthenticated = cognitoSession && cognitoSession.idToken ? true : false

  return (
    <Route
      { ...rest }
      render={ (props: any) => (
        (isAuthRoutes(props.match.url) && isAuthenticated)
          ? <Redirect to={ CORE_APP_ROOT } />
          : <Component { ...props } />
      ) }
    />
  )
}

export default PublicRoute
