import { api } from '@extensiv-app/utility'
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import axios from 'axios'

import { AUTH_ROUTES } from '@core/constants/coreRoutes'

import { UserPoolId, ClientId, API_URL, STAGE } from './config'
import { setLoginFlagOnLocalStorage } from './localstorage'

if (!UserPoolId || !ClientId) {
  console.error(`User pool id and client id not found`)
}

let currentClientId = ClientId

// Check if there is a refresh token, and use that one's client ID for cognito if
// there is.
// const refreshToken = Object.entries(localStorage).find(([k, v]) => k.endsWith('refreshToken'))
// if (refreshToken) {
//   currentClientId = refreshToken[0].split('.')[1]
// }

let cognitoUserPool = new CognitoUserPool({ UserPoolId, ClientId: currentClientId })
export let cognitoUser: CognitoUser

export const isAuthRoutes = (url: any) => Object.values(AUTH_ROUTES).includes(url)

export const signIn = async (credentials: any) => {
  const cognitoAuthenticationDetails = new AuthenticationDetails(credentials)
  const userData = { Username: credentials.Username, Pool: cognitoUserPool }
  cognitoUser = new CognitoUser(userData)
  // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')

  return new Promise((resolve, reject) => {
    return cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
      onSuccess: (session: any) => {
        // console.log('onSuccess => ', session, userConfirmationNecessary)
        setLoginFlagOnLocalStorage('true')
        resolve({ type: 'onSuccess', data: session })
      },
      onFailure: reject,
      customChallenge: async (challengeParameters) => {
        console.log('challengeParameters =>', challengeParameters)
        // return resolve({ type: 'customChallenge', data: challengeParameters })
        //
        cognitoUser.getCachedDeviceKeyAndPassword()
        // cognitoUser.getDevice({
        //     onSuccess: (success: string) => { console.log('getDevice success =>', success) },
        //     onFailure: (err: Error) => { console.log('getDevice err =>', err) }
        // })
        const cognitoUpdateUser: any = cognitoUser
        const response: any = await sendCustomChallengeAnswer(cognitoUpdateUser.deviceKey || '0000')

        if (response.type === 'customChallenge') {
          return resolve({ type: 'customChallenge', data: challengeParameters })
        } else if (response.type === 'onSuccess') {
          return resolve({ type: 'onSuccess', data: response.data })
        } else if (response.type === 'onFailure') {
          return reject({ type: 'onFailure', data: response.data })
        }
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        console.log('newPasswordRequired => ', userAttributes, requiredAttributes)
        // the api doesn't accept this field back
        delete userAttributes.email_verified
        delete userAttributes.email

        // store userAttributes on global variable
        const sessionUserAttributes = userAttributes
        return resolve({ type: 'newPasswordRequired', data: sessionUserAttributes })
        // we are going to handle this with cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes)
      },
      selectMFAType: (challengeName: any, challengeParameters: any) => {
        console.log('mfaSetup => ', challengeName, challengeParameters)
      },
      totpRequired: (type: any) => {
        console.log('totpRequired type =>', type)
        resolve({ type: 'totpRequired', data: type })
      },
      mfaSetup: (challengeName: any, challengeParameters: any) => {
        console.log('mfaSetup => ', challengeName, challengeParameters)
        cognitoUser.associateSoftwareToken({
          associateSecretCode: (softwareToken: string) => {
            console.log('softwareToken => ', softwareToken)
            alert('Add this code to your authenticator app \nYou can also copy from console\n ' + softwareToken)

            let challengeAnswer = null
            do {
              challengeAnswer = prompt('Please input the TOTP code')
            } while (challengeAnswer !== null && challengeAnswer === '')

            if (challengeAnswer !== null && challengeAnswer !== '') {
              cognitoUser.verifySoftwareToken(challengeAnswer, '', {
                onSuccess: (session: CognitoUserSession) => {
                  resolve({ type: 'mfaSetup', data: session })
                },
                onFailure: (err: Error) => {
                  reject(err)
                },
              })
            } else {
              reject()
            }
          },
          onFailure: (err: any) => {
            reject(err)
          },
        })
      },
      mfaRequired: (challengeName: any, challengeParameters: any) => {
        console.log('mfaRequired => ', challengeName, challengeParameters)
        const confirmationCode: any = prompt('Please input verification code: ', '')
        // MFA is required to complete user authentication.
        // Get the code from user and call
        // cognitoUser.sendMFACode(mfaCode, e)
        const mfaType = 'email'
        cognitoUser.sendMFACode(confirmationCode, {
          onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
            console.log(session, userConfirmationNecessary)
            resolve(userConfirmationNecessary)
          },
          onFailure: (err: any) => {
            reject(err)
          },
        }, mfaType)
      },
    })
  })
}

export const sendCustomChallengeAnswer = async (code: any) => new Promise((resolve, reject) => cognitoUser.sendCustomChallengeAnswer(
  code,
  {
    onSuccess: (session: CognitoUserSession) => {
      resolve({ type: 'onSuccess', data: session })
    },
    onFailure: (err: any) => {
      reject({ type: 'onFailure', data: err })
    },
    newPasswordRequired: (userAttributes: any, requiredAttributes: any) => console.log('sendCustomChallengeAnswer > newPasswordRequired >', userAttributes, requiredAttributes),
    mfaRequired: (challengeName: any, challengeParameters: any) => console.log(challengeName, challengeParameters),
    totpRequired: (challengeName: any, challengeParameters: any) => console.log(challengeName, challengeParameters),
    customChallenge: (challengeParameters: any) => {
      // reject({ type: 'onFailure', data: challengeParameters })
      console.log('sendCustomChallengeAnswer > customChallenge > challengeParameters =', challengeParameters)
      resolve({ type: 'customChallenge', data: challengeParameters })
    },
    mfaSetup: (challengeName: any, challengeParameters: any) => console.log(challengeName, challengeParameters),
    selectMFAType: (challengeName: any, challengeParameters: any) => console.log(challengeName, challengeParameters),
  }
))

export const completeNewPasswordChallenge = async ({ newPassword, newPasswordSessionUserAttributes }: any) =>
  new Promise((resolve, reject) => {
    console.log(newPassword, newPasswordSessionUserAttributes)
    return cognitoUser.completeNewPasswordChallenge(newPassword, newPasswordSessionUserAttributes, {
      onSuccess: (session: CognitoUserSession) => {
        resolve({ type: 'onSuccess', data: session })
      },
      onFailure: (err: any) => {
        reject({ type: 'onFailure', data: err })
      },
      customChallenge: (challengeParameters: any) => {
        console.log('challengeParameters =>', challengeParameters)
      },
    })
  })

export const sendMFACode = async (code: any) => new Promise((resolve, reject) => {
  return cognitoUser.sendMFACode(code, {
    onSuccess: resolve,
    onFailure: reject,
  }, 'SOFTWARE_TOKEN_MFA')
})

export const getAttributeVerificationCode = async () => {
  // phone_number or email we can pass attribute dynamically as required like
  const user = getCurrentUser()
  return new Promise((resolve, reject) => {
    user?.getSession((err: any, session: any) => {
      console.log('getAttributeVerificationCode - session =>', session)
      if (!err) {
        return user.getAttributeVerificationCode('phone_number', {
          onSuccess: () => resolve(true),
          onFailure: reject,
          inputVerificationCode: (data: any) => {
            console.log(data)
            const verificationCode: any = prompt('Please input verification code: ', '')
            user?.verifyAttribute('phone_number', verificationCode, {
              onSuccess: (success) => resolve(success),
              onFailure: (e) => reject(e),
            })
          },
        })
      }
      reject(err)
    })
  })
}

export const resendConfirmationCode = (email: string) => {
  const userData = new CognitoUser({ Username: email, Pool: cognitoUserPool })
  return new Promise((resolve, reject) => {
    userData.resendConfirmationCode((err: any, res: any) => {
      if (err) {
        reject(err)
      }
      resolve(res)
    })
  })
}

export const register = (email: string, password: string) => new Promise((resolve, reject) => {
  cognitoUserPool.signUp(email, password, [], [], (err: any, res: any) => {
    if (err) {
      reject(err)
    }
    resolve(res)
  })
})

export const getCurrentUser = () => cognitoUserPool.getCurrentUser()

export const getSession = () => new Promise<CognitoUserSession>((resolve, reject) => {
  const user = getCurrentUser()
  if (user) {

    return user.getSession((err: any, session: any) => {
      if (!err) {
        // const device = user.getCachedDeviceKeyAndPassword()
        // console.log(device)
        // user.getDevice({
        //     onSuccess: (data) => { console.log(data) resolve(data) },
        //     onFailure: reject,
        // })

        cognitoUser = user
        // console.log({ user }, { session })
        return resolve(session)
      }
      reject(err)
    })
  }
  reject('No User Found!')
})

export const confirmRegistration = (email: string, code: string) => {
  const userData = new CognitoUser({ Username: email, Pool: cognitoUserPool })
  return new Promise((resolve, reject) => {
    userData.confirmRegistration(code, true, (err: any, res: any) => {
      if (err) {
        reject(err)
      }
      resolve(res)
    })
  })
}

export const changePassword = (oldPassword: string, newPassword: string) => new Promise((resolve, reject) => {
  return cognitoUser.changePassword(oldPassword, newPassword, (err: any, res: any) => {
    if (err) {
      reject(err)
    }
    resolve(res)
  })
})

export const forgotPassword = (email: string) => {
  return axios.put(`${API_URL}/forgot-password`, undefined, {
    params: { email },
    headers: {},
  })
}

export const confirmPassword = (email: string, verificationCode: string, newPassword: string) => {
  const userData = new CognitoUser({ Username: email, Pool: cognitoUserPool })
  return new Promise((resolve, reject) => {
    userData.confirmPassword(
      verificationCode,
      newPassword,
      {
        onSuccess: () => { resolve(true) },
        onFailure: (err: Error) => { reject(err) }
      }
    )
  })
}

export const signOut = () => {
  const userData = getCurrentUser()
  return new Promise((resolve, reject) => {
    if (userData) {
      return userData.signOut(() => {
        resolve(true)
        currentClientId = ClientId
      })
    }
    reject(false)
  })
}

export const globalSignOut = () => {
  // const userData = getCurrentUser()
  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      return cognitoUser.globalSignOut({
        onSuccess: (msg: string) => {
          console.log(msg)
          resolve(true)
        },
        onFailure: (err: Error) => {
          console.log('globalSignOut > onFailure >', err)
          return resolve(signOut())
          // reject(err)
        }
      })
    }
    reject(false)
  })
}

export const setDeviceStatusRemembered = () => {
  return new Promise((resolve, reject) => {
    cognitoUser.setDeviceStatusRemembered({
      onSuccess: resolve,
      onFailure: reject,
    })
  })
}

export const setDeviceStatusNotRemembered = () => {
  cognitoUser.getCachedDeviceKeyAndPassword()
  return new Promise((resolve, reject) => {
    console.log('setDeviceStatusNotRemembered cognitoUser', cognitoUser)
    cognitoUser.setDeviceStatusNotRemembered({
      onSuccess: resolve,
      onFailure: reject,
    })
  })
}

export const forgetDevice = () => new Promise((resolve, reject) => {
  return cognitoUser.forgetDevice({
    onSuccess: resolve,
    onFailure: reject,
  })
})

export const listDevices = () => new Promise((resolve, reject) => {
  return cognitoUser.listDevices(1, null, {
    onSuccess: resolve,
    onFailure: reject,
  })
})

export const getDevice = () => new Promise((resolve, reject) => {
  return cognitoUser.getDevice({
    onSuccess: resolve,
    onFailure: reject,
  })
})

export const getUserData = async () => {
  // const userData = new CognitoUser({ Username: email, Pool: cognitoUserPool })
  const currentUser = cognitoUserPool.getCurrentUser()
  currentUser?.getSession((err: any, session: any) => {
    console.log('session => ', session)
    if (!err) {
      // console.log('getUserData session', session)

      // user must have phone number verified to enable sms mfa
      // set sms mfa starts
      // const smsMfaSettings = {
      //     PreferredMfa: true,
      //     Enabled: true,
      // }

      // currentUser.setUserMfaPreference(smsMfaSettings, null, (err1, attributes) => {
      //     if (err1) {
      //         // Handle error
      //         console.log(err1)
      //     } else {
      //         console.log('getUserData setUserMfaPreference', attributes)
      //         // Do something with attributes
      //     }
      // })
      // set sms mfa ends

      // add phone number attribute starts
      // const attributeList = []
      // const attribute = {
      //     Name: 'phone_number',
      //     Value: '+918401428558',
      // }
      // const attributes = new CognitoUserAttribute(attribute)
      // attributeList.push(attributes)

      // currentUser.updateAttributes(attributeList, (e: any, result) => {
      //     if (e) {
      //         alert(err.message || JSON.stringify(err))
      //         return
      //     }
      //     console.log('call result: ' + result)
      // })
      // add phone number attribute ends


      currentUser?.getUserData((err1: any, data: any) => {
        if (err1) {
          alert(err.message || JSON.stringify(err))
          return
        }
      })
    }
  })
  // // console.log('enableMFA userData', userData)
  // console.log('enableMFA currentUser', currentUser)
  // // console.log('enableMFA session', session)
  // // if (session) {
  // //     console.log('enableMFA session', session)
  // // }

  return new Promise((resolve, reject) => {
    // if (currentUser) {
    //     currentUser.getUserAttributes((err, data) => {
    //         console.log('err', err)
    //         console.log('data', data)

    resolve(true)
    //     })
    // }
    reject(false)

    // session?.getUserData((err, data) => {
    //     if (err) {
    //         alert(err.message || JSON.stringify(err))
    //         return
    //     }
    //     // const { PreferredMfaSetting, UserMFASettingList } = data
    //     // console.log(
    //     //     JSON.stringify({ PreferredMfaSetting, UserMFASettingList }, null, 2)
    //     // )
    // })
  })
}

export const getUserAttributes = () => {
  return new Promise((resolve, reject) => {
    const currentUser = cognitoUserPool.getCurrentUser()
    return currentUser?.getUserAttributes((err, data) => {
      if (err) {
        return reject(err.message || JSON.stringify(err))
      }
      resolve(data)
    })
  })
}

export const refreshSessionWithToken= (refreshToken: CognitoRefreshToken) => {
  return new Promise(async (resolve, reject) => {

    return cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) { return reject(err) }
      return resolve(session)
    })
  })
}

export const setSelectedOrgToUserAttribute: (orgId: string | null) => Promise<CognitoUserSession> = async orgId => {
  const attributeToAdd = {
    Name: 'custom:org',
    Value: String(orgId),
  }
  const attributes = [ new CognitoUserAttribute(attributeToAdd) ]
  await cognitoUser.getUserAttributes(err => {
    if (err) console.log(err)
  })



  return new Promise((resolve, reject) => {
    return cognitoUser.updateAttributes(attributes, async (err) => {
      if (err) {
        console.log(err)
        return reject(err.message || JSON.stringify(err))
      }
      const session: any = await getSession()
      const refresh_token = session.getRefreshToken()
      if (session.idToken.payload.org !== orgId) {
        await cognitoUser.refreshSession(refresh_token, async (err) => {
          if (err) {
            console.log(err)
            return reject(err)
          }
          resolve(await getSession())
        })
      } else {
        resolve(session)
      }
    })
  })
}

export const authGetUserInfo = async (userId: string) => await api.auth.get(`/${userId}/info`)

export const authGetUser = async (userId: string) => await api.auth.get(`/${userId}`)

export const apiGetAllOrgs = async () => await api.org.get('/')

export const apiGetOrganizationDetails = async () => await api.org.get(`/org`)

export const apiGetCurrentOrg = async () => await api.org.get(`/current`)

export const apiGetLoginContent = async () => await api.settings.get('/logincontent')

export const postSsoAuthCode = async (code: string) => {
  const stageToEnvMap: {[k: string]: string} = {
    'LOCAL': '-dev',
    'DEV': '-dev',
    'QA': '-qa',
    'QA2': '-qa2',
    'QA3': '-qa3',
    'QA4': '-qa4',
    'SANDBOX': '-sandbox',
    'STAGING': '-staging',
    'STAGING2': '-staging2',
    'PROD': ''
  }
  // currentClientId = 'pafbptfdhrt4tm2hs4qfp2sg'
  cognitoUserPool = new CognitoUserPool({ UserPoolId, ClientId: currentClientId })
  const env = stageToEnvMap[STAGE]
  const redirectUri = `https://app${env}.extensiv.com/sso`
  const ssoUrl = `https://auth${env}.extensiv.com/oauth2/token?grant_type=authorization_code`

  const { status, data } = await api.post(
    `${ssoUrl}&client_id=${currentClientId}&code=${code}&redirect_uri=${redirectUri}`,
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  )

  if (status != 200) return

  const { sub } = JSON.parse(atob(data.id_token.split('.')[1]))

  const userData = { Username: sub, Pool: cognitoUserPool }
  cognitoUser = new CognitoUser(userData)

  const res: any = await refreshSessionWithToken(new CognitoRefreshToken({ RefreshToken: data.refresh_token }))

  return !!res.idToken
}
